.map-icon-container {
  width: 200px;
  font-size: 1em;
  color: red;
  font-weight: bold;
}
.popup-map {
  width: 350px;
}
.map-marker-details-table {
  max-width: 300px;
}
.text-center {
  text-align: center;
}

.map-marker-details-table > td,
.map-marker-details-table > th {
  width: 50%;
}
.jstree-themeicon-custom {
  background-size: 72% !important;
}
